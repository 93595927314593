import React, { useRef, useContext, useEffect } from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faUserShield, faUserLock} from '@fortawesome/free-solid-svg-icons'
import ContentWrapper from "styles/contentWrapper"


const StyledSection = styled.section`
  width: 100%;
  height: auto;
  background: ${({ theme }) => theme.colors.white};
  padding: 3rem 0;
  .section-title {
    margin-bottom: 3rem;
    padding: 0 1rem;
  }
`

const StyledContentWrapper = styled(ContentWrapper)`
  && {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: 0;
    padding-left: 0;
    @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
      padding-right: 2.5rem;
      padding-left: 2.5rem;
    }
  }
`

const StyledInner = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  div {
    padding: 2rem 1rem;
    text-align: justify;
    max-width: 35rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    line-height: 1.85rem;
    font-size: 1rem;
    text-align: justify;
    letter-spacing: -.05rem;
    .icon {
      font-size: 3rem;
      margin-bottom: 1.5rem;
      color: ${({ theme }) => theme.colors.secondary};
    }
    h3 {
      margin-bottom: 0.5rem;
    }
  }

`

const Security = () => {

  return (
    <StyledSection id="security">
      <StyledContentWrapper>
        <h2 className="section-title">Trusted by CIO, loved by CHRO</h2>
        <StyledInner>
          <div>
            <FontAwesomeIcon className="icon" icon={faUserLock} />
              <h3>Security at the core</h3>
              <p>
                Data security is at the top priority for HrFlow.ai. 
                We invest in securing our infrastructure in close partnership 
                with security experts and cloud providers. In 2018, HrFlow.ai (ex Riminder.net) 
                has been recognized by Amazon AWS in the <a href="https://aws.amazon.com/fr/blogs/france/klap-meilleure-architecture-startup-aws-summit-paris-2019/" target="_blank" rel="nofollow noopener">TOP8 of well architected infrastructure in France.</a>
                <br/> <a href="https://developers.hrflow.ai/docs/security">Visit our Security Hub</a>
              </p>
          </div>
          <div>
            <FontAwesomeIcon className="icon"  icon={faUserShield} />
            <h3>Privacy by design</h3>
            <p>
              <Link to="/">HrFlow.ai</Link>'s infrastructure for storing, decrypting, and transmitting data runs
              on cloud architectures that meet the highest industry standards and regulatory
              requirements—such as GDPR. In 2017, HrFlow.ai (ex Riminder.net) has been recognized
              by <a href="https://www.businessinsider.fr/voici-les-12-premieres-startups-facebook-startup-garage-station-f-2017#a-hrefhttps-alaneu-main-companyalan-a-alan-propose-un-service-dassurance-maladie-numerique-et-est-le-premier-assureur-independant-lance-en-france-depuis-30-ans-a-hrefhttps-wwwbusinessinsiderfr-alan-premier-assureur-independant-cree-en-30-ans-leve-12-millions-eurosalan-a-leve-12-millions-deuros-en-octobre-2016-a" target="_blank" rel="nofollow noopener">Facebook </a>
              &nbsp;	as one the leading private by design platforms in Europe.
              <br/> <a href="https://developers.hrflow.ai/docs/gdpr">Visit our G.D.P.R. Hub</a>
            </p>
          </div>
        </StyledInner>
      </StyledContentWrapper>
    </StyledSection>
  )
}


export default Security